<template>
  <div>
    <el-card class="box-card card-contanier">
      <div class="card-content"></div>
      <div class="card-content">
        <el-form :inline="true" @submit.native.prevent>
          <div style="float: left; width: 90%">
            <el-form-item label="发送类型">
              <el-select v-model="sendType" placeholder="发送类型">
                <el-option value="" label="全部">全部</el-option>
                <el-option
                  v-for="item in sendTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="推送礼品">
              <el-input
                v-model="sendGifts"
                placeholder="推送礼品"
                size="medium"
                clearable
                @keyup.enter.native="search"
              ></el-input>
            </el-form-item>
            <el-form-item label="活动名称">
              <el-input
                v-model="senLabel"
                placeholder="活动名称"
                size="medium"
                clearable
                @keyup.enter.native="search"
              ></el-input>
            </el-form-item>
            <el-form-item label="群发时间:">
              <el-date-picker
                v-model="sendTime"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="备注">
              <el-input
                v-model="sendContent"
                placeholder="备注"
                size="medium"
                clearable
                @keyup.enter.native="search"
              ></el-input>
            </el-form-item>
            <el-form-item label="自定义链接">
              <el-input
                v-model="sendUrlSearch"
                placeholder="自定义链接"
                size="medium"
                clearable
                @keyup.enter.native="search"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="search" icon="el-icon-search" size="medium"
                >搜索</el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button @click="refresh" icon="el-icon-refresh" size="medium"
                >重置</el-button
              >
            </el-form-item>
          </div>
        </el-form>
      </div>
    </el-card>

    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      size="mini"
      border
      stripe
    >
      <el-table-column type="index" label="序号" :index="indexMethod" align="center">
      </el-table-column>
      <el-table-column
        prop="belongName"
        width="160"
        show-overflow-tooltip
        label="记录所属"
        align="center"
      >
      </el-table-column>
      <el-table-column prop="sendLevel" width="80" label="商盟/商户" align="center">
        <template slot-scope="scope">
          <el-tag type="success" size="small" v-if="scope.row.sendLevel === 0"
            >商盟</el-tag
          >
          <el-tag type="success" size="small" v-else-if="scope.row.sendLevel === 1"
            >商户</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column prop="sendType" label="发送类型" align="center" width="120">
        <template slot-scope="scope">
          <el-tag type="success" size="small" v-if="scope.row.sendType === 0"
            >标签发送</el-tag
          >
          <el-tag type="success" size="small" v-else-if="scope.row.sendType === 1"
            >点对点发送</el-tag
          >
          <el-tag type="success" size="small" v-else-if="scope.row.sendType === 2"
            >会员群发</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="sendGifts"
        show-overflow-tooltip
        label="推送礼品"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="sendUrl"
        show-overflow-tooltip
        label="自定义链接"
        align="center"
      >
        <template slot-scope="scope">
          <span
            @click="showCopyDialog(scope.row.sendUrl, scope.row.sendLabel)"
            style="font-size: 12px; color: skyblue; cursor: pointer"
            >{{ scope.row.sendUrl }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="sendLabel"
        show-overflow-tooltip
        label="活动名称"
        align="center"
      >
        <template slot-scope="scope">
          <span
            @click="showCopyDialog(scope.row.sendLabel, scope.row.sendLabel)"
            style="font-size: 12px; color: skyblue; cursor: pointer"
            >{{ scope.row.sendLabel }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="success" width="80" label="成功人数" align="center">
      </el-table-column>
      <el-table-column prop="fail" width="80" label="失败人数" align="center">
      </el-table-column>
      <el-table-column prop="sendTime" width="150" label="群发时间" align="center">
      </el-table-column>
      <el-table-column prop="sendStat" width="150" label="发送进度" align="center">
        <template slot-scope="scope">
          <el-progress :percentage="scope.row.sendStat" status="success"></el-progress>
        </template>
      </el-table-column>
      <el-table-column
        prop="sendContent"
        width="200"
        label="备注"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span
            @click="showCopyDialog(scope.row.sendContent, scope.row.sendLabel)"
            style="font-size: 12px; color: skyblue; cursor: pointer"
            >{{ scope.row.sendContent }}</span
          >
        </template>
      </el-table-column>
      <el-table-column prop="icon" label="操作" width="200" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button @click="abortSend(scope.row)" type="text" size="small"
            >终止发送</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <el-button @click="endOutUser(scope.row)" type="text" size="small"
            >发送人员</el-button
          >
          <el-divider direction="vertical"></el-divider>
          <el-popconfirm
            title="确认删除吗？"
            icon="el-icon-info"
            icon-color="red"
            @confirm="sendDelete(scope.row.segId)"
          >
            <el-button type="text" slot="reference">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 50, 100]"
      :current-page="current"
      :page-size="size"
      :total="total"
    ></el-pagination>
    <!--发送人员弹出框-->
    <el-dialog
      :title="sendLabel"
      :visible.sync="dialogEndOutUser"
      width="70%"
      :before-close="endOutUserClose"
      :close-on-click-modal="false"
    >
      <div
        class="alert alert-success"
        style="
          margin-top: -30px !important;
          margin-left: 10px !important;
          margin-right: 10px !important;
        "
      >
        注：短信发送只针对在该活动中未关注公众号会员发送短信
      </div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="卡卷/链接群发详情" name="first">
          <el-card class="card-contanier">
            <div class="card-content">
              <el-form :inline="true" @submit.native.prevent>
                <div style="float: left; width: 90%">
                  <el-form-item label="会员昵称:">
                    <el-input
                      v-model="pvipNickname"
                      clearable
                      placeholder="会员昵称"
                      size="medium"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="状态:">
                    <el-select v-model="failType" placeholder="是否关注公众号">
                      <el-option value="" label="全部">全部</el-option>
                      <el-option
                        v-for="item in failTypeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      @click="searchEndOutUser"
                      icon="el-icon-search"
                      size="medium"
                      >搜索</el-button
                    >
                  </el-form-item>
                  <el-form-item>
                    <el-button
                      type="primary"
                      @click="excelExport"
                      icon="el-icon-download"
                      size="medium"
                      >导出</el-button
                    >
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="resendClick" size="medium"
                      >短信发送</el-button
                    >
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </el-card>

          <el-table
            ref="multipleTable"
            :data="personnelData"
            tooltip-effect="dark"
            border
            style="width: 100%"
            stripe
            size="small"
            :row-style="{ height: '40px' }"
            :cell-style="{ padding: '1px 0' }"
          >
            <el-table-column type="index" label="序号" align="center"> </el-table-column>
            <el-table-column
              align="center"
              property="fail_name"
              label="会员姓名"
            ></el-table-column>
            <el-table-column
              align="center"
              property="pvip_nickname"
              label="会员昵称"
            ></el-table-column>
            <el-table-column
              align="center"
              property="pvip_phone"
              label="联系方式"
            ></el-table-column>
            <el-table-column
              align="center"
              property="fail_dat"
              label="发送时间"
            ></el-table-column>
            <el-table-column align="center" property="state" label="状态">
              <template slot-scope="scope">
                <el-tag type="success" size="small" v-if="scope.row.fail_type === 1"
                  >成功</el-tag
                >
                <el-tag type="danger" size="danger" v-else-if="scope.row.fail_type === 0"
                  >失败</el-tag
                >
              </template>
            </el-table-column>
            <el-table-column
              width="150"
              align="center"
              property="fail_reason"
              label="失败原因"
            ></el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              @size-change="endOutSizeChange"
              @current-change="endOutCurrentChange"
              layout="total, sizes, prev, pager, next, jumper"
              :page-sizes="[10, 20, 50, 100]"
              :current-page="endOutCurrent"
              :page-size="endOutSize"
              :total="endOutTotal"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="短信群发详情" name="second">
          <el-table
            ref="multipleTable"
            :data="SyTextMessagesLogs"
            tooltip-effect="dark"
            border
            style="width: 100%"
            stripe
            size="small"
            :row-style="{ height: '40px' }"
            :cell-style="{ padding: '1px 0' }"
          >
            <el-table-column type="index" label="序号" align="center"> </el-table-column>
            <el-table-column
              align="center"
              property="msgSendName"
              label="活动名称"
            ></el-table-column>
            <el-table-column
              align="center"
              property="msgSendObj"
              label="参与对象"
            ></el-table-column>
            <el-table-column
              align="center"
              property="msgSendContent"
              label="推送内容"
            ></el-table-column>
            <el-table-column
              align="center"
              property="msgSendDm"
              label="发送时间"
            ></el-table-column>
            <el-table-column align="center" property="msgSendStat" label="状态">
              <template slot-scope="scope">
                <el-tag type="success" size="small" v-if="scope.row.msgSendStat === 1"
                  >成功</el-tag
                >
                <el-tag
                  type="danger"
                  size="small"
                  v-else-if="scope.row.msgSendStat === 0"
                  >失败</el-tag
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="block">
            <el-pagination
              @size-change="smSizeChange"
              @current-change="smCurrentChange"
              layout="total, sizes, prev, pager, next, jumper"
              :page-sizes="[10, 20, 50, 100]"
              :current-page="smCurrent"
              :page-size="smSize"
              :total="smTotal"
            ></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>

      <div style="margin-top: 30px"></div>
    </el-dialog>
    <el-dialog
      title="发送短信"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        ref="smsContent"
        :model="smsContent"
        :rules="smsContentRules"
        label-width="80px"
      >
        <el-form-item label="短信内容" prop="content">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入短信内容"
            v-model="smsContent.content"
          >
          </el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="smsSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :title="copytitle"
      :visible.sync="copydialog"
      width="30%"
      :before-close="handleCopyClose"
    >
      <el-input type="textarea" :rows="4" :disabled="true" v-model="copearea"></el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCopyClose">取 消</el-button>
        <el-button type="primary" @click="copyContent">复 制</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "../../api/sendlogs";
let intervals;
export default {
  name: "AssetType",
  data() {
    return {
      //复制框
      copydialog: false,
      copearea: "",
      copytitle: "",

      total: 0,
      size: 10,
      current: 1,
      endOutTotal: 0,
      endOutSize: 10,
      endOutCurrent: 1,
      smTotal: 0,
      smSize: 10,
      smCurrent: 1,
      tableData: [],
      sendContent: "",
      personnelData: [],
      SyTextMessagesLogs: [],
      dialogEndOutUser: false,
      dialogVisible: false,
      sendType: "", //发送类型
      smsContent: {}, //短信内容
      sendTypeOptions: [
        {
          value: 0,
          label: "标签发送",
        },
        {
          value: 1,
          label: "点对点发送",
        },
        {
          value: 2,
          label: "会员群发",
        },
      ],
      smsContentRules: {
        content: [{ required: true, message: "请输入短信内容", trigger: "blur" }],
      },
      sendGifts: "", //推送礼品
      senLabel: "", //活动名称
      segId: "", //群发id
      pvipNickname: "", //会员昵称
      failType: "", //状态
      sendTime: "", //群发时间
      sendCouBatch: "", //批次
      sendGift: "", //推送礼品
      sendTemp: "", //发送模板
      sendUrl: "", //自定义链接
      first: "", //发送名称
      remark: "", //备注

      sendTotal: 0, //发送总数
      sendSurplus: 0, //发送剩余
      sendSucc: 0, //发送成功，
      sendFail: 0, //发送失败
      activeName: "first",
      sendUrlSearch: "", //自定义链接查询
      failTypeOptions: [
        {
          value: 1,
          label: "成功",
        },
        {
          value: 0,
          label: "失败",
        },
      ],
      sendLabel: "",
      endOutForm: {},
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getList();
    this.intervalGetList();
  },
  destroyed() {
    clearInterval(intervals);
  },
  methods: {
    refresh() {
      this.sendGifts = "";
      this.senLabel = "";
      this.sendType = "";
      this.sendContent = "";
      this.sendTime = "";
      this.sendUrlSearch = "";
      this.current = 1;
      this.getList();
    },
    search() {
      this.current = 1;
      this.getList();
    },
    searchEndOutUser() {
      this.endOutCurrent = 1;
      this.endOutUser(this.endOutForm);
    },
    intervalGetList() {
      intervals = setInterval((res) => {
        this.getList();
      }, 5000);
    },
    //复制框
    showCopyDialog(content, title) {
      this.copydialog = true;
      this.copearea = content;
      this.copytitle = title;
    },
    handleCopyClose() {
      this.copydialog = false;
      this.copearea = "";
      this.copytitle = "";
    },
    copyContent() {
      let oInput = document.createElement("input");
      //这边为链接地址this.liveLink='www.baidu.com'
      oInput.value = this.copearea;
      document.body.appendChild(oInput);
      oInput.select();
      console.log(oInput.value);
      document.execCommand("Copy");
      oInput.remove();
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    //列表数据查询
    getList() {
      api
        .getList(
          this.current,
          this.size,
          this.sendContent,
          this.sendType,
          this.sendGifts,
          this.senLabel,
          this.sendTime,
          this.sendUrlSearch
        )
        .then((res) => {
          let tables = res.data.data.result.records;
          this.tableData = [];
          tables.forEach((element) => {
            let sendOver = Number(element.success) + Number(element.fail);
            if (element.sendPersons === 0) {
              element.sendStat = 100;
            } else if (element.sendPersons !== 0 && sendOver >= element.sendPersons) {
              element.sendStat = 100;
            } else if (element.sendPersons !== 0 && sendOver < element.sendPersons) {
              let sendStat = (sendOver / Number(element.sendPersons)).toFixed(2) * 100;
              element.sendStat = sendStat;
            }
            this.tableData.push(element);
          });
          this.size = res.data.data.result.size;
          this.total = res.data.data.result.total;
        });
    },
    indexMethod(index) {
      index = index + 1 + (this.current - 1) * this.size;
      return index;
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getList();
    },
    endOutSizeChange(val) {
      this.endOutSize = val;
      this.endOutUser(this.endOutForm);
    },
    endOutCurrentChange(val) {
      this.endOutCurrent = val;
      this.endOutUser(this.endOutForm);
    },
    smSizeChange(val) {
      this.smSize = val;
      this.endOutUser(this.endOutForm);
    },
    smCurrentChange(val) {
      this.smCurrent = val;
      this.endOutUser(this.endOutForm);
    },
    endOutUserClose() {
      this.pvipNickname = "";
      this.dialogEndOutUser = false;
    },
    excelExport() {
      let url =
        "/api/sendlogs/exportExcel?segId=" +
        this.segId +
        "&pvipNickname=" +
        this.pvipNickname +
        "&failType=" +
        this.failType +
        "&total=" +
        this.endOutTotal;
      //console.log(this.fn.baseUrl);
      window.location.href = this.fn.baseUrl + url;
    },
    resendClick() {
      this.dialogVisible = true;
    },
    smsSubmit() {
      let params = {
        segId: this.segId,
        content: this.smsContent.content,
        sendLabel: this.sendLabel,
      };
      this.$refs["smsContent"].validate((valid) => {
        if (valid) {
          api.getResend(params).then((res) => {
            if (res.data.data.result === "success") {
              this.$notify({
                title: "提示信息",
                message: "发送成功",
                type: "success",
                position: "bottom-right",
              });
              this.dialogEndOutUser = false;
              this.dialogVisible = false;
              this.smsContent = {};
              this.getList();
            } else if (res.data.data.result === "wrong") {
              this.$notify({
                title: "提示信息",
                message: "密码错误",
                type: "error",
                position: "bottom-right",
              });
            } else if (res.data.data.result === "noExistent") {
              this.$notify({
                title: "提示信息",
                message: "短信发送只针对在该活动中未关注公众号会员发送短信",
                type: "error",
                position: "bottom-right",
              });
            } else {
              this.$notify({
                title: "提示信息",
                message: "发送失败",
                type: "error",
                position: "bottom-right",
              });
            }
          });
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.smsContent = {};
    },

    //终止发送
    abortSend(row){
      api.abortSend(row.sendNonce).then(res=>{
        console.log(res)
      })
    },

    //发送人员
    endOutUser(row) {
      this.endOutForm = row;
      this.sendLabel = row.sendLabel;
      this.segId = row.segId;
      this.sendCouBatch = row.sendCouBatch;
      this.sendGift = row.sendGifts;
      this.sendTemp = row.sendTemp;
      this.sendUrl = row.sendUrl;
      this.first = row.sendLabel;
      this.remark = row.sendContent;
      api
        .getFailedList(
          row.segId,
          this.pvipNickname,
          this.failType,
          this.endOutCurrent,
          this.endOutSize
        )
        .then((res) => {
          this.personnelData = res.data.data.result.records;
          this.endOutSize = res.data.data.result.size;
          this.endOutTotal = res.data.data.result.total;
        });
      api.getSyTextMessagesLogsList(row.segId).then((res) => {
        this.SyTextMessagesLogs = res.data.data.result.records;
        this.smSize = res.data.data.result.size;
        this.smTotal = res.data.data.result.total;
      });
      this.dialogEndOutUser = true;
    },
    sendDelete(segId) {
      api.getSendDelete(segId).then((res) => {
        if (res.data.data.result) {
          this.$notify({
            title: "提示信息",
            message: "删除成功",
            type: "success",
            position: "bottom-right",
          });
          this.getList();
        } else {
          this.$notify({
            title: "提示信息",
            message: "删除失败",
            type: "error",
            position: "bottom-right",
          });
        }
      });
    },
    // sendInfosOBS(segId) {
    //   var interval = setInterval(async () => {
    //     let res = await api.getSendInfosOBS(segId);
    //     if (this.sendSurplus == 0 || this.sendSurplus < 0) {
    //       clearInterval(interval);
    //     } else {
    //       this.sendSurplus = res.data.data.result.surplus; //发送剩余
    //       this.sendTotal = res.data.data.result.total; //发送总数
    //       this.sendSucc = res.data.data.result.succ; //发送总数
    //       this.sendFail = res.data.data.result.fail; //发送总数
    //     }
    //   }, 2000);
    // },
  },
};
</script>

<style scoped>
.card-contanier {
  margin-bottom: 5px;
  margin-top: 20px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.map {
  width: 100%;
  height: 400px;
}
.el-pagination {
  float: right;
  margin-top: 22px;
}
.alert {
  border-radius: 6px;
}
.alert-success {
  background-color: #f4f8fb !important;
  color: #7a8094;
  border: aliceblue;
  margin: 15px 0px 0px 0px !important;
  padding: 10px;
}
</style>
