import { postRequest, getRequest, deleteRequest } from '../utils/request'
export default {
    getList(current,size,sendContent,sendType,sendGifts,senLabel,sendTime,sendUrl){
        let sendStartDate = "";
        let sendEndDate = "";
        if(sendTime != null && sendTime != ''){
            sendStartDate = sendTime[0];
            sendEndDate = sendTime[1]
        }
        return getRequest(`/api/sendlogs/list?current=${current}&sendContent=${sendContent}&sendType=${sendType}&sendGifts=${sendGifts}&senLabel=${senLabel}&size=${size}&sendStartDate=${sendStartDate}&sendEndDate=${sendEndDate}&sendUrl=${sendUrl}`)
    },
    getFailedList(segId,pvipNickname,failType,current,size){
        return getRequest(`/api/sendlogs/getFailedList?segId=${segId}&pvipNickname=${pvipNickname}&failType=${failType}&current=${current}&size=${size}`)
    },
    getResend(params){
        return postRequest(`/api/test/getResend`,params)
    },
    getSendInfosOBS(segId){
        return getRequest(`/api/sendlogs/getsendinfos?segId=${segId}`)
    },
    getSendDelete(segId){
        return getRequest(`/api/sendlogs/getSendDelete?segId=${segId}`)
    },
    getSyTextMessagesLogsList(segId){
        return getRequest(`/syTextMessagesLogs/list?segId=${segId}`)
    },
    abortSend(nonce){
        console.log(nonce)
        return getRequest(`/api/sendlogs/abortSend?nonce=${nonce}`)
    }
}